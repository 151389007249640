<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import _ from "lodash";

export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Datatable,
    Pagination,
  },
  data() {
    let sortOrders = [];
    let columns = [
      { width: "50px", label: "", name: "-" },
      { width: "50px", label: "No", name: "-" },
      { width: "100px", label: "ID Jabatan Kompetensi", name: "-" },
      { width: "auto", label: "Jabatan", name: "nama_jabatan" },
      { width: "auto", label: "Job Code", name: "kode_job" },
      { width: "auto", label: "Job Name", name: "nama_job" },
      {
        width: "auto",
        label: "Judul Kompetensi",
        name: "kompetensi.nama_kompetensi",
      },
      { width: "auto", label: "Level Kompetensi", name: "-" },
      { width: "auto", label: "Tipe Kompetensi", name: "-" },
    ];

    // Get hak akses
    const menu = "Jabatan Kompetensi";

    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    return {
      title: menu,
      isWrite: localStorage.getItem("isWrite"),
      items: [
        {
          text: "Master Kompetensi",
          href: "/",
        },
        {
          text: "Jabatan Kompetensi",
          active: true,
        },
      ],
      isLoading: false,
      columns: columns,
      sortKey: "nama_jabatan",
      sortOrders: sortOrders,
      tableFilter: {
        page: 1,
        per_page: 10,
        jabatan: "",
        kompetensi: "",
        level: "",
        kode_job: "",
        nama_job: "",
        status: 1,
        tipe_kompetensi: "",
      },
      tableData: [],
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
      listLevel: [],
      modalImport: false,
      optionFilterKompetensi: [],
      optionFilterJabatan: [],
      optionFilterJobCode: [],
      optionFilterJobName: [],
      optionFilterTipeKompetensi: [],
      listJabatanDitandai: [],
    };
  },
  async mounted() {
    this.getData();
    this.getJabatanKompetensiFilter();
    this.getKompetensiFilter();
    this.getLevel();
    this.getTipeKompetensi();

    this.filterJabatan = _.debounce(
      this.searchJabatanKompetensi.bind(this),
      500
    );
    this.filterKompetensi = _.debounce(this.searchKompetensi.bind(this), 500);
    this.filterJobCode = _.debounce(this.searchJobCode.bind(this), 500);
    this.filterJobName = _.debounce(this.searchJobName.bind(this), 500);
    this.filterTipeKompetensi = _.debounce(
      this.searchTipeKompetensi.bind(this),
      500
    );
  },
  methods: {
    async getLevel() {
      const data = await axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION + "master/level-kompetensi",
          {
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
              Accept: "Application/json",
            },
          }
        )
        .then((response) => response.data.data)
        .catch((err) => {
          console.log(err);
        });
      if (data) {
        this.listLevel = data;
        console.log(this.listLevel);
      }
    },
    async searchJabatanKompetensi(search, loading) {
      loading(true);
      this.getJabatanKompetensiFilter(search, loading);
    },
    async getJabatanKompetensiFilter(search = "", loading) {
      await axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "referensi/jabatan-kompetensi/all",
          {
            params: {
              search: search,
              limit: 10,
            },
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
          }
        )
        .then((response) => {
          this.optionFilterJabatan = response.data.data;
          if (loading) loading(false);
        });
    },
    async searchKompetensi(search, loading) {
      loading(true);
      this.getKompetensiFilter(search, loading);
    },
    async getKompetensiFilter(search = "", loading) {
      await axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/kompetensi/all",
          {
            params: {
              search: search,
              limit: 10,
            },
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
          }
        )
        .then((response) => {
          this.optionFilterKompetensi = response.data.data;
          if (loading) loading(false);
        });
    },
    async searchTipeKompetensi(search, loading) {
      loading(true);
      this.getTipeKompetensi(search, loading);
    },
    async getTipeKompetensi(search = "", loading) {
      await axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "referensi/tipe_kompetensi/all",
          {
            params: {
              search: search,
              limit: 50,
            },
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
          }
        )
        .then((response) => {
          this.optionFilterTipeKompetensi = response.data.data;
          if (loading) loading(false);
        });
    },
    async getData() {
      this.isLoading = true;
      const data = await axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION + "master/jabatan-kompetensi",
          {
            params: this.tableFilter,
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
              Accept: "Application/json",
            },
          }
        )
        .then((response) => response.data)
        .catch((err) => {
          let errResponse = err.response.data;
          if (errResponse.meta.code == 403) {
            Swal.fire({
              icon: "error",
              title: "Terjadi Kesalahan",
              text: errResponse.meta.message,
            });
            this.$router.push({ name: "logout" });
            return false;
          }
          Swal.fire({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: errResponse.data,
          });

          console.log(errResponse);
          return false;
        });

      if (data) {
        this.tableData = data.data.data;
        this.pagination.lastPage = data.data.last_page;
        this.pagination.currentPage = data.data.current_page;
        this.pagination.total = data.data.total;
        this.pagination.lastPageUrl = data.data.last_page_url;
        this.pagination.nextPageUrl = data.data.next_page_url;
        this.pagination.prevPageUrl = data.data.prev_page_url;
        this.pagination.from = data.data.from;
        this.pagination.to = data.data.to;
        this.pagination.links = data.data.links;
      }
      this.isLoading = false;
    },
    searchJobCode(search, loading) {
      loading(true);
      this.getJobCodeName(this.tableFilter.jabatan, "jobCode", loading, search);
    },
    searchJobName(search, loading) {
      loading(true);
      this.getJobCodeName(this.tableFilter.jabatan, "jobName", loading, search);
    },
    async getJobCodeName(jabatan, type = "all", loading, search) {
      const data = await axios({
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/jabatan-kompetensi/job-code/" +
          jabatan,
        headers: {
          Authorization: `Bearer ${localStorage.access_token}`,
        },
        params: {
          search: search,
          limit: 50,
        },
      })
        .then((response) => response.data.data)
        .catch((err) => {
          console.log("err get job code and job name:", err);
          return [];
        });

      if (data) {
        switch (type) {
          case "jobCode":
            this.optionFilterJobCode = [];
            this.optionFilterJobCode = data.map((job) => job.kode_job);
            break;
          case "jobName":
            this.optionFilterJobName = [];
            this.optionFilterJobName = data.map((job) => job.nama_job);
            break;
          default:
            this.optionFilterJobName = [];
            this.optionFilterJobCode = [];
            this.optionFilterJobName = data.map((job) => job.nama_job);
            this.optionFilterJobCode = data.map((job) => job.kode_job);
            break;
        }
      }
      if (loading) loading(false);
    },
    toPage(url) {
      let link = new URLSearchParams(url);
      this.tableFilter.page = link.get("page");
      // this.tableFilter.per_page = link.get('per_page');
      this.getData();
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      this.tableFilter.column = key;
      this.tableFilter.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getData();
    },
    downloadTemplate() {
      window.open(
        process.env.VUE_APP_BACKEND_URL_VERSION +
          "download-template/jabatan-kompetensi",
        "_blank"
      );
    },
    async deleteData() {
      Swal.fire({
        icon: "warning",
        title: "Apakah Anda yakin menghapus data terpilih ?",
        showCancelButton: true,
        cancelButtonText: "Tidak",
        confirmButtonText: "Ya, yakin !",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = await axios({
            method: "delete",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "master/jabatan-kompetensi",
            data: {
              jabatan_kompetensi_id: this.listJabatanDitandai,
            },
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
          })
            .then((response) => response.data.data)
            .catch((err) => {
              console.log(err);
              Swal.fire({
                icon: "warning",
                title: "Terjadi Kesalahan",
                timer: 3000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              });
              return false;
            });
          if (data) {
            this.alertBerhasil();
          }
        }
      });
    },
    async importData() {
      this.importFile = this.$refs.file.files[0];

      Swal.fire({
        icon: "warning",
        title: "Mohon tunggu",
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      await axios
        .post(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "master/jabatan-kompetensi/import-excel",
          {
            file: this.importFile,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Create data berhasil",
            timer: 1000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          });
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "warning",
            title: "Terjadi Kesalahan",
            timer: 3000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          });
        });

      this.modalImport = false;
      this.$refs.file.files = null;
      this.getData();
    },
    async exportExcel() {
      // Swal.fire({
      //   icon: 'warning',
      //   title: 'Mohon tunggu',
      //   allowOutsideClick: false,
      //   showConfirmButton: false,
      // });
      // await axios
      //   .get(
      //     process.env.VUE_APP_BACKEND_URL_VERSION +
      //       'master/jabatan-kompetensi/export-excel',
      //     {
      //       headers: {
      //         Authorization: 'Bearer ' + localStorage.access_token,
      //         Accept: 'Application/json',
      //       },
      //     }
      //   )
      //   .then((response) => {
      //     const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
      //     // Membuat elemen <a> untuk mengeksekusi unduhan
      //     const link = document.createElement('a');
      //     link.href = blobUrl;
      //     link.download = 'Jabatan Kompetensi.xlsx';

      //     // Menambahkan elemen <a> ke dalam DOM dan mengkliknya
      //     document.body.appendChild(link);
      //     link.click();

      //     // Menghapus elemen <a> setelah unduhan selesai
      //     document.body.removeChild(link);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      // Swal.close();
      const queryParams = new URLSearchParams(
        Object.entries(this.tableFilter).filter(([value]) => value !== "") // Hanya memasukkan nilai yang tidak kosong
      ).toString();
      window.open(
        process.env.VUE_APP_BACKEND_URL_VERSION +
          "export-excel/jabatan-kompetensi?" +
          queryParams
      );
    },
    alertBerhasil() {
      let timerInterval;
      Swal.fire({
        icon: "success",
        title: "Berhasil",
        text: "Data berhasil dihapus.",
        timer: 2000,
        timerProgressBar: true,
        showCancelButton: false,
        showConfirmButton: false,
        didOpen: () => {
          timerInterval = setInterval(() => {
            const content = Swal.getContent();
            if (content) {
              const b = content.querySelector("b");
              if (b) {
                b.textContent = Swal.getTimerLeft();
              }
            }
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          this.getData();
        }
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-end gap-2 mb-2">
              <button
                class="btn btn-success btn-sm"
                @click="modalImport = true"
                v-if="isWrite == 1"
              >
                <i class="fa fa-file-excel me-1"></i> Import Jabatan Kompetensi
              </button>
              <button class="btn btn-warning btn-sm" @click="exportExcel()">
                <i class="fa fa-file-excel me-1"></i> Export Jabatan Kompetensi
              </button>
            </div>
            <hr />
            <div class="row">
              <div class="form-group col-md-4 mt-2">
                <label for="filterJabatan">Jabatan</label>
                <v-select
                  id="filterJabatan"
                  class="style-chooser"
                  :options="optionFilterJabatan"
                  :reduce="(jabatan) => jabatan.nama_jabatan"
                  label="nama_jabatan"
                  v-model="tableFilter.jabatan"
                  placeholder="Filter Jabatan"
                  @search="filterJabatan"
                  @update:modelValue="
                    getJobCodeName(tableFilter.jabatan, 'all');
                    getData();
                  "
                >
                </v-select>
              </div>
              <div class="form-group col-md-4 mt-2">
                <label for="filterKompetensi">Kompetensi</label>
                <v-select
                  id="filterKompetensi"
                  class="style-chooser"
                  :options="optionFilterKompetensi"
                  :reduce="(komptensi) => komptensi.nama_kompetensi"
                  label="nama_kompetensi"
                  v-model="tableFilter.kompetensi"
                  placeholder="Filter Jabatan"
                  @search="filterKompetensi"
                  @update:modelValue="getData"
                >
                </v-select>
              </div>
              <div class="form-group col-md-4 mt-2">
                <label for="filterLevel">Level</label>
                <select
                  class="form-control"
                  id="filterLevel"
                  v-model="tableFilter.level"
                  @update:modelValue="getData"
                >
                  <option value="">Semua Level</option>
                  <option
                    v-for="level in listLevel"
                    :key="level.idlevel_kompetensi"
                    :value="level.idlevel_kompetensi"
                  >
                    {{ level.nama_level }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-4 mt-2">
                <label for="filterJobCode">Job Code</label>
                <v-select
                  id="filterJobCode"
                  class="style-chooser"
                  :options="optionFilterJobCode"
                  v-model="tableFilter.kode_job"
                  placeholder="Filter Job Code"
                  @search="filterJobCode"
                  @update:modelValue="getData"
                />
              </div>
              <div class="form-group col-md-4 mt-2">
                <label for="filterJobName">Job Name</label>
                <v-select
                  id="filterJobName"
                  class="style-chooser"
                  :options="optionFilterJobName"
                  v-model="tableFilter.nama_job"
                  placeholder="Filter Job Name"
                  @search="filterJobName"
                  @update:modelValue="getData"
                />
              </div>
              <div
                class="col-md-4 mt-2 d-flex align-items-end justify-content-end gap-2"
              >
                <div class="form-group flex-fill">
                  <label for="filterTipeKompetensi">Tipe Kompetensi</label>
                  <v-select
                    id="filterTipeKompetensi"
                    class="style-chooser"
                    :options="optionFilterTipeKompetensi"
                    :reduce="(item) => item.tipe_kompetensi"
                    label="tipe_kompetensi"
                    v-model="tableFilter.tipe_kompetensi"
                    placeholder="Filter Tipe Kompetensi"
                    @search="filterTipeKompetensi"
                    @update:modelValue="getData"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div class="row mb-2">
              <div class="d-flex justify-content-between">
                <table>
                  <tr>
                    <td>Show</td>
                    <td>
                      <select
                        class="form-control form-control-sm"
                        id="showpaginatetable"
                        v-model="tableFilter.per_page"
                        @change="getData()"
                      >
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                      </select>
                    </td>
                    <td>Entries</td>
                  </tr>
                </table>
                <div>
                  <button
                    class="btn btn-danger btn-sm"
                    :disabled="!listJabatanDitandai.length"
                    @click="deleteData()"
                    v-if="isWrite == 1"
                  >
                    <i class="fa fa-trash me-1"></i>
                    Hapus Jabatan Kompetensi Terpilih
                  </button>
                </div>
              </div>
              <!-- <div class="col-5">&nbsp;</div>
              <div class="col-3">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="searchTable"
                  placeholder="Cari Data ..."
                />
              </div> -->
            </div>
            <div class="row">
              <Datatable
                :columns="columns"
                :sortKey="sortKey"
                :sortOrders="sortOrders"
                @sort="sortBy"
              >
                <tbody>
                  <tr v-if="isLoading">
                    <td class="text-center" colspan="99">
                      <i class="fas fa-spinner fa-spin"></i> Loading...
                    </td>
                  </tr>
                  <tr v-else-if="tableData == ''">
                    <td class="text-center" colspan="99">
                      Data Tidak Tersedia
                    </td>
                  </tr>
                  <tr
                    v-else
                    v-for="(row, index) in tableData"
                    :key="row.idjabatan_kompetensi"
                  >
                    <td class="text-center" v-if="isWrite == 1">
                      <input
                        type="checkbox"
                        class="largerCheckbox"
                        :value="row.idjabatan_kompetensi"
                        v-model="listJabatanDitandai"
                      />
                    </td>
                    <td v-else></td>
                    <td>{{ pagination.from + index }}</td>
                    <td>{{ row.idjabatan_kompetensi }}</td>
                    <td>{{ row.nama_jabatan }}</td>
                    <td>{{ row.kode_job }}</td>
                    <td>{{ row.nama_job }}</td>
                    <td>{{ row.kompetensi?.nama_kompetensi }}</td>
                    <td>{{ row.kompetensi?.level_kompetensi?.nama_level }}</td>
                    <td>{{ row.tipe_kompetensi }}</td>
                  </tr>
                </tbody>
              </Datatable>
            </div>
            <pagination :pagination="pagination" @to-page="toPage"></pagination>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>

  <b-modal
    v-model="modalImport"
    title="Import Jabatan Kompetensi"
    size="md"
    hide-footer
  >
    <div class="row">
      <div class="col-12 mb-2">
        <button class="btn btn-success btn-sm" @click="downloadTemplate()">
          <i class="fa fa-download me-1"></i> Download Template
        </button>
      </div>
      <div class="col-12 mb-2">
        <div class="form-group">
          <label for="file_import_excel">File Excel</label>
          <input
            type="file"
            id="file_import_excel"
            class="form-control"
            ref="file"
            accept=".xlsx,.xls"
            mime
          />
        </div>
      </div>
      <div class="col-12">
        <button class="btn btn-primary btn-sm float-end" @click="importData()">
          <i class="fa fa-save me-1"></i> Save
        </button>
      </div>
    </div>
  </b-modal>
</template>

<style scoped>
input.largerCheckbox {
  width: 20px;
  height: 20px;
}
</style>
